body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tabs > button {
  border-bottom: none;
  padding-bottom: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  text-transform: none;
  font-size: 14px;
}

.tabs > button:first-of-type {
  margin-left: 5px;
}

.tabs {
  border-bottom: 2px solid #cdc7e4;
  width: 100%;
  border-radius: 0px !important;
  margin-bottom: 15px;
}

.personListName {
  color: #1d0056;
}